@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Bitter', serif;
}

.mailbox {
  position: relative;
}

.mailbox::after {
  content: url("/src/assets/ArrowEmail.svg");
  position: absolute;
  right: -30px;
  top: 33px;
}

@media screen and (max-width: 768px) {
  .mailbox::after {
    display: none;
  }
}
